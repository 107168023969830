// 文件位置：service/userService.ts
import axios from 'axios';
import config from "@/services/config";
/**
 * 通过搜索关键字和会员状态获取用户列表。
 * @param {string} search_key - 搜索关键字。
 * @param {number} vip - 会员状态，-1 表示所有用户。
 * @param {number} page - 当前页。
 * @param {number} pagesize - 每页的用户数量。
 * @returns {Promise<{users: User[], total: number}>} 成功返回包含用户列表和总数的对象，失败返回空列表和0。
 */
export const getUserList = async (search_key, vip, page, pagesize) => {
    try {
        // 构建请求数据对象
        const data = {
            search_keyword: search_key, // 搜索关键字
            page: page, // 当前页码
            page_size: pagesize, // 每页项目数量
            status: vip // 会员状态
        };
        let response = null;
        // 根据会员状态决定请求的URL
        vip = Number(vip); //强制转为number型，防止出错
        if (vip === -1) {
            response = await axios.post(`${config.apiUserUrl}/getusers`, data);
        }
        else {
            response = await axios.post(`${config.apiUserUrl}/get_vips`, data);
        }
        // 检查响应状态码是否为200
        if (response.status === 200) {
            // 返回包含用户列表和总数的对象
            return {
                users: response.data.users,
                total: response.data.total
            };
        }
        else {
            // 非预期的响应状态，记录错误日志
            console.error('Unexpected response status:', response.status);
            return {
                users: [],
                total: 0
            };
        }
    }
    catch (error) {
        // 捕获并处理请求中的任何错误
        console.error('Error fetching user list:', error.toString());
        return {
            users: [],
            total: 0
        };
    }
};
/**
 * 根据用户ID获取用户信息。
 * @param {number} userId - 用户ID。
 * @returns {Promise<User | null>} 成功返回用户信息，失败返回null。
 */
export const getUserById = async (userId) => {
    try {
        // 发送 GET 请求以获取指定 ID 的用户数据
        const response = await axios.get(`${config.apiUserUrl}/getuser/${userId}`);
        // 检查响应状态码是否为200
        if (response.status === 200) {
            // 返回用户数据
            return response.data;
        }
        else {
            // 非预期的响应状态码，记录错误日志
            console.error('Unexpected status code:', response.status);
            return null;
        }
    }
    catch (error) {
        // 捕获并处理请求中的任何错误
        console.error('Failed to fetch user:', error?.response?.status || error.message);
        // 处理特定的错误，例如用户未找到
        if (error.response?.status === 404) {
            console.error('User not found:', userId);
        }
        return null;
    }
};
/**
 * 发送登录请求，验证用户身份。
 * @param {string} username - 用户名。
 * @param {string} password - 密码。
 * @returns {Promise<{success: boolean, station: string, user?: User}>} 返回登录状态，包括是否成功、状态码和用户信息（如果登录成功）。
 */
export const login = async (username, password) => {
    try {
        // 登录URL
        const url = `${config.apiUserUrl}/login`;
        // 使用URLSearchParams构建请求参数
        const params = new URLSearchParams();
        params.append('username', username); // 添加用户名
        params.append('password', password); // 添加密码
        console.log("看看是啥：" + url);
        // 发送POST请求进行登录
        const response = await axios.post(url, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        // 检查返回的状态字段
        if (response.data.status === "0") {
            // 登录成功，返回用户信息
            return {
                success: true,
                station: response.data.status,
                user: response.data.user
            };
        }
        else {
            // 登录失败，返回失败状态
            return {
                success: false,
                station: response.data.status
            };
        }
    }
    catch (error) {
        // 捕获并处理请求中的任何错误
        console.error('Error during login:', error);
        throw new Error(`Login failed: ${error.response?.statusText || error.message}`);
    }
};
/**
 * 发送登出请求到服务器。
 * @returns {Promise<boolean>} 成功返回 true，失败返回 false。
 */
export const logout = async () => {
    try {
        // 登出URL
        const url = `${config.apiUserUrl}/logout`;
        // 发送POST请求进行登出
        const response = await axios.post(url, {});
        // 检查返回的状态字段是否为success
        return response.data.status === "0";
    }
    catch (error) {
        // 捕获并处理请求中的任何错误
        console.error('Error during logout:', error);
        return false;
    }
};
/**
 * 注册新用户。
 * @param {User} user - 用户对象。
 * @param {File | null} avatar_pic - 用户头像文件，可以为空。
 * @returns {Promise<string>} 成功返回状态码，失败返回 "3"。
 */
export const registerUser = async (user, avatar_pic) => {
    try {
        // 注册URL
        const url = `${config.apiUserUrl}/register`;
        // 使用FormData构建请求数据
        const formData = new FormData();
        formData.append('user_data', JSON.stringify(user)); // 添加用户数据
        // 如果头像文件不为空，添加到FormData
        if (avatar_pic) {
            formData.append('file', avatar_pic);
        }
        // 发送POST请求进行注册
        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        // 返回服务器返回的状态码
        return response.data;
    }
    catch (error) {
        // 捕获并处理请求中的任何错误
        console.error('Error during register:', error);
        return "3";
    }
};
/**
 * 重置用户密码。
 * @param {number} login_id - 用户登录ID。
 * @param {string} new_pass - 新密码。
 * @returns {Promise<string>} 成功返回 "success"，失败返回 "failed"。
 */
export const forgotPassword = async (login_id, new_pass) => {
    try {
        // 重置密码URL
        const url = `${config.apiUserUrl}/forgot-password`;
        // 发送POST请求重置密码
        const response = await axios.post(url, { login_id, new_pass });
        // 检查返回的状态字段是否为0
        return response.data.station === "0" ? "success" : "failed";
    }
    catch (error) {
        // 捕获并处理请求中的任何错误
        console.error('Error during reset password:', error);
        return "failed";
    }
};
/**
 * 删除用户。
 * @param {number[]} userIds - 要删除的用户ID数组。
 * @returns {Promise<string>} 成功返回状态码，失败返回 "failed"。
 */
export const deleteUsers = async (userIds) => {
    try {
        // 删除用户URL
        const url = `${config.apiUserUrl}/del_users`;
        // 发送POST请求删除用户
        const response = await axios.delete(url, { data: userIds });
        // 返回服务器返回的状态码
        return response.data.status;
    }
    catch (error) {
        // 捕获并处理请求中的任何错误
        console.error('Error during deleting users:', error);
        return "1";
    }
};
