// src/stores/themeStore.ts
import { defineStore } from 'pinia';
import { getSunriseSunset, getUserLocation } from "@/services/tools";
export const useThemeStore = defineStore('theme', {
    state: () => ({
        isDarkMode: false,
        sunrise: 0,
        sunset: 0
    }),
    getters: {
        getIsDarkMode: (state) => state.isDarkMode // 获取当前暗模式状态
    },
    actions: {
        toggleDarkMode() {
            this.isDarkMode = !this.isDarkMode;
            localStorage.setItem('dark-mode', this.isDarkMode.toString());
            if (this.isDarkMode) {
                document.documentElement.classList.add('dark');
            }
            else {
                document.documentElement.classList.remove('dark');
            }
        },
        compareTime(sr_time, ss_time) {
            const now = new Date();
            // 比较时间
            const startTotalSeconds = sr_time.getHours() * 3600 + sr_time.getMinutes() * 60 + sr_time.getSeconds();
            const endTotalSeconds = ss_time.getHours() * 3600 + ss_time.getMinutes() * 60 + ss_time.getSeconds();
        },
        async setSunTime() {
            const location = await getUserLocation();
            const { sunrise, sunset } = await getSunriseSunset(location.latitude, location.longitude);
            const startTotalSeconds = sunrise.getHours() * 3600 + sunrise.getMinutes() * 60 + sunrise.getSeconds();
            const endTotalSeconds = sunset.getHours() * 3600 + sunset.getMinutes() * 60 + sunset.getSeconds();
            localStorage.setItem("sr", startTotalSeconds.toString());
            localStorage.setItem("ss", endTotalSeconds.toString());
        },
        async initTheme() {
            // this.isDarkMode = localStorage.getItem('dark-mode') === 'true';
            // if (this.isDarkMode) {
            //   document.documentElement.classList.add('dark');
            // } else {
            //   document.documentElement.classList.remove('dark');
            // }
            try {
                const now = new Date();
                const sr = localStorage.getItem('sr') || '';
                const ss = localStorage.getItem('ss') || '';
                this.sunrise = Number(sr);
                this.sunset = Number(ss);
                const currentTotalSeconds = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
                this.isDarkMode = currentTotalSeconds < this.sunrise || currentTotalSeconds > this.sunset;
                localStorage.setItem('dark-mode', this.isDarkMode.toString());
                if (this.isDarkMode) {
                    document.documentElement.classList.add('dark');
                }
                else {
                    document.documentElement.classList.remove('dark');
                }
            }
            catch (error) {
                console.error('Error initializing theme:', error);
            }
        },
    }
});
