import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import router from './router';
import '@/setups/axios-setup';
import i18n from '@/setups/i18n-setup';
import { useThemeStore } from './stores/themeStore';
import setupElementPlus from '@/setups/element-plus-setup';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@/assets/icons';
import './assets/styles/global.scss';
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(createPinia());
app.use(router);
setupElementPlus(app);
app.use(i18n);
app.mount('#app');
const themeStore = useThemeStore();
themeStore.initTheme()
    .catch(error => {
    console.error('Error initializing theme:', error);
});
themeStore.setSunTime()
    .catch(error => {
    console.error('Error setSun theme:', error);
});
