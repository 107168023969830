// router/index.ts
import { createRouter, createWebHistory } from 'vue-router';
import { checkLogin } from "@/services/tools";
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/blank.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/prompt',
        name: 'prompt_manage',
        component: () => import('../views/prompt_lib_m.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/usermanage',
        name: 'user_manage',
        component: () => import('../views/user_lib_m.vue'),
        meta: {
            requiresAuth: true
        }
    }
    // 添加其他路由配置
];
const router = createRouter({
    history: createWebHistory(),
    routes
});
// 在 Vue Router 设置中
router.beforeEach(async (to, from, next) => {
    const isLoggedIn = await checkLogin();
    // 检查是否已登录
    if (isLoggedIn && (to.name === 'login' || to.name === 'home')) {
        next({ name: 'prompt_manage' });
        return; // 确保后续逻辑不会执行
    }
    // 检查目标页面是否需要认证
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isLoggedIn) {
            next({ name: 'login' }); // 未登录则跳转到登录页
        }
        else {
            next(); // 已登录，且目标页需要认证，正常放行
        }
    }
    else {
        next(); // 目标页不需要认证，正常放行
    }
});
export default router;
