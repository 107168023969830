// 文件位置：service/tools.ts
import axios from "axios";
import config from "@/services/config";
import CryptoJS from "crypto-js";
import { nextTick, onMounted, onUnmounted, ref } from 'vue';
import { format } from 'date-fns';
import { ElLoading, ElMessageBox, ElNotification } from "element-plus";
/**
 * 显示加载指示器
 * @param {Function} t - 国际化函数
 * @param {HTMLElement} target - 需要显示加载指示器的目标元素
 * @returns {ReturnType<typeof ElLoading.service>} 加载指示器实例
 */
export const showLoading = (t, target) => {
    return ElLoading.service({
        target: target,
        lock: true,
        text: t('message.loading')
    });
};
/**
 * 检查用户是否已登录
 * @returns {Promise<boolean>} - 返回一个 Promise，表示用户是否已登录
 */
export const checkLogin = async () => {
    try {
        const response = await axios.get(`${config.apiUserUrl}/check-login`);
        return response.data.logged_in;
    }
    catch (error) {
        console.error('检查用户登录状态时发生错误:', error);
        return false;
    }
};
/**
 * 加密数据
 * @param {object} data - 需要加密的对象数据
 * @returns {string} - 加密后的字符串
 */
export const encryptData = (data) => {
    try {
        return CryptoJS.AES.encrypt(JSON.stringify(data), config.encryptionKey).toString();
    }
    catch (error) {
        console.error('加密数据时发生错误:', error);
        return '';
    }
};
/**
 * 解密数据
 * @param {string} ciphertext - 加密后的字符串
 * @returns {any} - 解密后的对象数据
 */
export const decryptData = (ciphertext) => {
    try {
        const bytes = CryptoJS.AES.decrypt(ciphertext, config.encryptionKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    }
    catch (error) {
        console.error('解密数据时发生错误:', error);
        return null;
    }
};
/**
 * 获取表格高度的自定义 Hook
 * @param {number} [offset=190] - 表格高度的偏移量，默认值为 190
 * @returns {Ref<number>} - 返回一个引用类型的表格高度
 */
export function useTableHeight(offset = 190) {
    const tableHeight = ref(0);
    /**
     * 处理窗口大小改变的函数
     */
    const handleResize = () => {
        nextTick(() => {
            const mainContainer = document.querySelector('.el-main');
            if (mainContainer) {
                tableHeight.value = mainContainer.clientHeight - offset;
            }
        });
    };
    onMounted(() => {
        if (tableHeight.value === 0) {
            handleResize();
        }
        window.addEventListener('resize', handleResize);
    });
    onUnmounted(() => {
        window.removeEventListener('resize', handleResize);
    });
    return tableHeight;
}
/**
 * 格式化日期
 * @param {Date | null | undefined} date - 需要格式化的日期对象
 * @param {string} [dateFormat='yyyy-MM-dd HH:mm'] - 日期格式字符串，默认值为 'yyyy-MM-dd HH:mm'
 * @returns {string} - 返回格式化后的日期字符串，如果日期为 null 或 undefined，则返回空字符串
 */
export function formatDate(date, dateFormat = 'yyyy-MM-dd HH:mm') {
    try {
        if (date) {
            return format(date, dateFormat);
        }
        else {
            return '';
        }
    }
    catch (error) {
        console.error('格式化日期时发生错误:', error);
        return '';
    }
}
/**
 * 显示通知
 * @param {'success' | 'warning' | 'info' | 'error'} type - 通知类型
 * @param {string} title - 通知标题
 * @param {string} message - 通知内容
 */
export function showNotification(type, title, message) {
    ElNotification({
        title,
        message,
        type,
    });
}
/**
 * 显示消息框
 * @param {string} title - 消息框标题
 * @param {string} message - 消息内容
 * @param {boolean} [isAlert=true] - 是否为警告框，默认为 true
 */
export async function showMessageBox(title, message, isAlert = true) {
    try {
        if (isAlert) {
            await ElMessageBox.alert(message, title, {
                confirmButtonText: 'OK',
                type: 'warning',
                center: true,
            });
        }
        else {
            await ElMessageBox.confirm(message, title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true,
            });
        }
    }
    catch (error) {
        if (error !== 'cancel') {
            console.error('显示消息框时发生错误:', error);
        }
        throw error;
    }
}
/**
 * 获取用户位置
 * @returns {Promise<{ latitude: number; longitude: number }>} - 返回包含纬度和经度的 Promise
 */
export async function getUserLocation() {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords;
                resolve({ latitude, longitude });
            }, error => {
                console.error('获取用户位置时发生错误:', error);
                reject(error);
            });
        }
        else {
            const error = new Error('此浏览器不支持地理定位。');
            console.error(error.message);
            reject(error);
        }
    });
}
/**
 * 获取日出日落时间
 * @param {number} latitude - 纬度
 * @param {number} longitude - 经度
 * @returns {Promise<{ sunrise: Date; sunset: Date }>} - 返回包含日出和日落时间的 Promise
 */
export async function getSunriseSunset(latitude, longitude) {
    try {
        const response = await fetch(`https://api.sunrise-sunset.org/json?lat=${latitude}&lng=${longitude}&formatted=0`);
        if (!response.ok) {
            throw new Error(`获取日出日落时间失败，HTTP 状态码: ${response.status}`);
        }
        const data = await response.json();
        return {
            sunrise: new Date(data.results.sunrise),
            sunset: new Date(data.results.sunset)
        };
    }
    catch (error) {
        console.error('获取日出日落时间时发生错误:', error);
        return {
            sunrise: new Date(),
            sunset: new Date()
        };
    }
}
