// 文件位置：src/stores/localStore.ts
import { defineStore } from 'pinia';
export const useLocaleStore = defineStore('locale', {
    state: () => ({
        locale: localStorage.getItem('locale') || 'zh'
    }),
    getters: {
        getLocale: (state) => state.locale
    },
    actions: {
        setLocale(newLocale) {
            this.locale = newLocale;
            localStorage.setItem('locale', newLocale);
        }
    }
});
