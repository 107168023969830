// config.ts
// 确保环境变量存在并且类型正确
const config = {
    apiUserUrl: process.env.VUE_APP_SK_USERS_API_URL || 'http://localhost:9000', // 提供默认值以防环境变量未设置
    apiPromptUrl: process.env.VUE_APP_SK_PROMPTS_API_URL || 'http://localhost:9000', // 提供默认值以防环境变量未设置
    encryptionKey: process.env.VUE_APP_ENCRYPTION_KEY || 'dont tell anyone',
    apiKey: process.env.VUE_APP_SK_API_KEY || 'dont tell anyone',
};
// 导出配置对象
export default config;
